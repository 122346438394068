<template>
  <v-text-field
    label="Titulo"
    v-model="titulo"
    dense
    outlined
    hide-details
    @click:clear="clearCliente"
  >
  </v-text-field>
</template>

<script>
export default {
  name: "Titulo",

  data() {
    return {
      titulo: null,
    };
  },

  watch: {
    titulo() {
      if (this.titulo) {
        this.$store.commit("Filtros/UPDATE_TITULO", this.titulo);
      }
    },
  },

  methods: {
    clearCliente() {
      this.$store.commit("Filtros/UPDATE_CLIENTE", null);
      this.cliente = null;
    },
  },
};
</script>

<style></style>
